import React, {ChangeEvent} from 'react'

import {Alert, Button, Form, FormInstance, Input, Select} from 'antd';
import axios from "axios";
import {IProps} from "../../../model/iprops";
import {CardDetails} from "../../../model/card-details";
import moment from "moment";
import {AiFillCheckCircle} from "react-icons/all";
import {Application} from "../../../model/application";

interface ComponentState {
    card: CardDetails
    application: Application
    policyVerified: boolean
    processing: boolean
    ownershipVerified: boolean
    verfyingOwnership: boolean
    otp: string
    verificationFailCount: number
    policyVerificationFailed: boolean,
    failureTitle: string,
    failureBody: string,
    status: string,
}

export class CardDetailsComponent extends React.Component<IProps, ComponentState> {
    constructor(props: IProps) {
        super(props);
        let card = props.application.card
        this.state = {
            application: props.application,
            card: card,
            policyVerified: card.verified,
            processing: false,
            ownershipVerified: false,
            verfyingOwnership: false,
            otp: "",
            verificationFailCount: 0,
            policyVerificationFailed: false,
            failureTitle: "",
            failureBody: "",
            status: "DRAFT"
        };
    }

    formRef = React.createRef<FormInstance>();

    onFinish = (values: any) => {
        values.policyNumber  = values.policyNumber.toUpperCase()
        this.setState({
            card: {
                ...values,
            },
            policyVerificationFailed: false,
            processing: true
        });

        if (this.state.verfyingOwnership) {
            let body = {
                "policyNumber": values["policyNumber"],
                "code": values["otp"]
            }
            axios.put("https://api.nyaradzo.sahwi.net/phone/validation", body).then((response) => {
                this.setState({
                    ...this.state,
                    processing: false,
                    verfyingOwnership: false
                });
                this.props.next(
                    {
                        ...this.state.application,
                        card: this.state.card
                    }, this.state.status === "PROCESSED" || this.state.status === "PROCESSING",
                    this.state.status === "ACCEPTED",
                )
            }).catch(error => {
                this.setState({
                    ...this.state,
                    processing: false,
                    verificationFailCount: this.state.verificationFailCount + 1,
                });
            });
        } else if (this.state.ownershipVerified) {
            this.setState({
                ...this.state,
                processing: false,
                verfyingOwnership: false,
            });
            this.props.next(
                {
                    ...this.state.application,
                    card: this.state.card
                },
                this.state.status === "PROCESSED" || this.state.status === "PROCESSING",
                this.state.status === "ACCEPTED",
            )
        } else {
            axios.post("https://api.nyaradzo.sahwi.net/policy/validation", values).then((response) => {
                let ownershipVerified = response.data.status.toUpperCase() === "DRAFT"

                this.setState({
                    ...this.state,
                    processing: false,
                    policyVerified: true,
                    ownershipVerified: ownershipVerified,
                    status: response.data.status.toUpperCase()
                });
                let card = this.state.card
                card.verified = true
                card.verifiedOn = moment()
                if (ownershipVerified) {
                    this.setState({
                        ...this.state,
                        processing: false,
                        verfyingOwnership: false
                    });
                    this.props.next(
                        {
                            ...this.state.application,
                            card: this.state.card,
                        },
                        false,
                        false)
                } else {
                    this.setState({
                        ...this.state,
                        application: response.data.application,
                        processing: false,
                        verfyingOwnership: true
                    });
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    policyVerificationFailed: true,
                    failureTitle: "Card Verification Failed",
                    failureBody: "The supplied card details could not be verified or are invalid.",
                    processing: false
                });
            });
        }

    };

    onFinishFailed = (errorInfo: any) => {
        this.setState({
            ...this.state,
            policyVerificationFailed: true,
            failureTitle: "Submission Failed",
            failureBody: "You have errors in your submission. Check the form for errors, fix and resubmit.",
            processing: false
        });
    };

    onCardDetailsChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            ...this.state,
            policyVerified: false,
            ownershipVerified: false,
            verificationFailCount: 0
        })
    }

    render() {
        const {Option} = Select;
        return (
            <div className="at-contentarea">

                <div className="at-sectionhead">
                    <h2>Enter Card Number</h2>
                    <div className="at-description">
                        <p>Please enter the card number and Pay@ number <br/> on your card to proceed.</p>
                    </div>
                </div>

                <div className="at-contentbox at-cardfromcontent">

                    {this.state.policyVerificationFailed &&

                        <div className="row">
                            <div className="col">
                                <Alert
                                    message={this.state.failureTitle}
                                    description={this.state.failureBody}
                                    type="error"
                                    showIcon
                                />
                                <br/>
                            </div>

                        </div>
                    }

                    <Form
                        ref={this.formRef}
                        name="basic"
                        layout="vertical"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <Form.Item
                                        label="Card Type"
                                        name="cardType"
                                        validateTrigger='onSubmit'
                                        initialValue={this.state.card.cardType}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please specify the card type!",
                                            }]}
                                    >
                                        <Select size="large" disabled={this.props.application.locked}>
                                            <Option value="premier">Premier Card</Option>
                                            <Option value="platinum">Platinum Card</Option>
                                            <Option value="diamond">Diamond Card</Option>
                                            <Option value="gold">Gold Card</Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <Form.Item
                                        label="Policy Number"
                                        name="policyNumber"
                                        validateTrigger='onSubmit'
                                        initialValue={this.state.card.policyNumber}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please provide the policy number on the card!",
                                            }]}
                                    >
                                        <Input
                                            disabled={this.props.application.locked}
                                            onChange={this.onCardDetailsChange}
                                            suffix={this.state.policyVerified ?
                                                <AiFillCheckCircle style={{color: 'green'}}
                                                                   className="site-form-item-icon"/> : ''}/>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                <div className="form-group">
                                    <Form.Item
                                        label="Pay@ Number"
                                        name="payAtNumber"
                                        validateTrigger='onSubmit'
                                        initialValue={this.state.card.payAtNumber}
                                        rules={[{
                                            required: true,
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message: 'Please input your pay@ number on the card!'
                                        }
                                        ]}
                                    >
                                        <Input
                                            disabled={this.props.application.locked}
                                            onChange={this.onCardDetailsChange}
                                            suffix={this.state.policyVerified ?
                                                <AiFillCheckCircle style={{color: 'green'}}
                                                                   className="site-form-item-icon"/> : ''}/>
                                    </Form.Item>
                                </div>
                            </div>

                            <Form.Item
                                name="verified"
                                initialValue={this.state.card.verified}
                                noStyle
                            >
                                <Input type="hidden"/>
                            </Form.Item>

                            <Form.Item
                                name="verifiedOn"
                                initialValue={this.state.card.verifiedOn}
                                noStyle
                            >
                                <Input type="hidden"/>
                            </Form.Item>
                        </div>

                        {this.state.verificationFailCount > 0 &&
                            <div className="row">
                                <div className="col">
                                    <Alert
                                        message="Verify Application Ownership"
                                        description="The supplied one time password is incorrect. We need to verify your ownership of this card. Kindly provide the one time password sent to your mobile phone"
                                        type="error"
                                        showIcon
                                    />
                                    <br/>
                                </div>

                            </div>
                        }

                        {this.state.policyVerified && !this.state.ownershipVerified && this.state.verificationFailCount === 0 &&

                            <div className="row">
                                <div className="col">
                                    <Alert
                                        message="Verify Application Ownership"
                                        description="We need to verify your ownership of this card. Kindly provide the one time password sent to your mobile phone"
                                        type="info"
                                        showIcon
                                    />
                                    <br/>
                                </div>

                            </div>
                        }
                        {this.state.policyVerified && !this.state.ownershipVerified &&
                            <div className="row">
                                <div className="col">


                                    <Form.Item
                                        label="Security Code"
                                        name="otp"
                                        validateTrigger='onSubmit'
                                        rules={[{
                                            required: true,
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message: 'Please input the one time password!'
                                        }
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <br/>
                                </div>
                            </div>
                        }

                        <div className="row">
                            <div className="col">
                                <Button type="primary"
                                        size="large"
                                        shape="round"
                                        htmlType="submit"
                                        className="zright"
                                        loading={this.state.processing}>
                                    {this.state.ownershipVerified ? 'Next' : 'Verify'}
                                </Button>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }

}
