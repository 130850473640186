import React from 'react'
import { HeaderComponent, FooterComponent} from '../shared'

export const Layout : React.FC = (props) => {
    
    return (
        <div className="at-wrapper" >
            <HeaderComponent />
                {props.children}
            <FooterComponent />
        </div>
    )
}