const images =  {
    logo: require('./logo.svg').default,
    sliderimg: require('./sliderimg.jpg').default,
    sliderimg1: require('./sliderimg1.jpg').default,
    sliderimg2: require('./sliderimg2.jpg').default,
    aboutimg: require('./aboutimg.png').default,
    cardplan: require('./cardplan-img.jpg').default,
    mapimage: require('./mapimg.png').default,
    goldpln: require('./icons/goldplan.svg').default,
    diamondlan: require('./icons/diamondplan.svg').default,
    platinumplan: require('./icons/platinumplan.svg').default,
    premierplan: require('./icons/premierplan.svg').default,
    user1: require('./testimonials/1.jpg').default,
    user2: require('./testimonials/2.jpg').default,
    user3: require('./testimonials/3.jpg').default,
    user4: require('./testimonials/4.jpg').default,
    user5: require('./testimonials/5.jpg').default,
    location01: require('./location/01.jpg').default,
    location02: require('./location/02.jpg').default,
    location03: require('./location/03.jpg').default,
    location04: require('./location/04.jpg').default,
    location05: require('./location/05.jpg').default,
    location06: require('./location/06.jpg').default,
    congratulationimg: require('./congratulation-img.png').default,
    cardCheckers: require('./cards/checkers.png').default,
    cardPickNPay: require('./cards/pick-n-pay.png').default,
    cardShoprite: require('./cards/shoprite.png').default,
    cardSpar: require('./cards/spar.png').default,
};

export default images;