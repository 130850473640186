import {Area} from "antd-country-phone-input";

export function getGenderOptions() {
    return [
        {label: 'Male', value: 'Male'},
        {label: 'Female', value: 'Female'},
    ];
}

export function getMaritalStatusOptions() {
    return [
        {label: 'Married', value: 'married'},
        {label: 'Single', value: 'single'},
        {label: 'Divorced', value: 'divorced'},
    ];
}

export function getTitles() {
    const titles =
        [
            {
                "ID": 5,
                "Title1": "ADV"
            },
            {
                "ID": 4,
                "Title1": "DR"
            },
            {
                "ID": 11,
                "Title1": "HON"
            },
            {
                "ID": 3,
                "Title1": "MISS"
            },
            {
                "ID": 1,
                "Title1": "MR"
            },
            {
                "ID": 2,
                "Title1": "MRS"
            },
            {
                "ID": 10,
                "Title1": "MS"
            },
            {
                "ID": 8,
                "Title1": "PROF"
            },
            {
                "ID": 9,
                "Title1": "REV"
            },
            {
                "ID": 12,
                "Title1": "SIR"
            }
        ];
    titles.sort((title1, title2) => title1.ID - title2.ID)
    let titleOptions: { value: string; label: string; }[] = []
    titles.forEach(title => titleOptions.push({value: title.Title1, label: title.Title1}))
    return titleOptions;
}

export function getRelations() {
    const relations =
        [
            {
                "RelationID": 1,
                "Relation1": "AUNT"
            },
            {
                "RelationID": 2,
                "Relation1": "BROTHER"
            },
            {
                "RelationID": 3,
                "Relation1": "BROTHER-IN-LAW"
            },
            {
                "RelationID": 4,
                "Relation1": "CHILD"
            },
            {
                "RelationID": 5,
                "Relation1": "FATHER"
            },
            {
                "RelationID": 6,
                "Relation1": "FATHER-IN-LAW"
            },
            {
                "RelationID": 7,
                "Relation1": "GRANDDAUGHTER"
            },
            {
                "RelationID": 8,
                "Relation1": "GRANDFATHER"
            },
            {
                "RelationID": 9,
                "Relation1": "GRANDMOTHER"
            },
            {
                "RelationID": 10,
                "Relation1": "GRANDSON"
            },
            {
                "RelationID": 11,
                "Relation1": "MOTHER"
            },
            {
                "RelationID": 12,
                "Relation1": "MOTHER-IN-LAW"
            },
            {
                "RelationID": 13,
                "Relation1": "NEPHEW"
            },
            {
                "RelationID": 14,
                "Relation1": "NIECE"
            },
            {
                "RelationID": 15,
                "Relation1": "SISTER"
            },
            {
                "RelationID": 16,
                "Relation1": "SISTER-IN-LAW"
            },
            {
                "RelationID": 17,
                "Relation1": "SPOUSE"
            },
            {
                "RelationID": 18,
                "Relation1": "UNCLE"
            },
            {
                "RelationID": 19,
                "Relation1": "EXTENDED"
            },
            {
                "RelationID": 20,
                "Relation1": "OTHER"
            },
            {
                "RelationID": 21,
                "Relation1": "COUSIN"
            },
            {
                "RelationID": 22,
                "Relation1": "WIDER CHILD"
            },
            {
                "RelationID": 23,
                "Relation1": "SON"
            },
            {
                "RelationID": 24,
                "Relation1": "DAUGHTER"
            },
            {
                "RelationID": 25,
                "Relation1": "NEXT OF KIN"
            },
            {
                "RelationID": 26,
                "Relation1": "DAUGHTER-IN-LAW"
            },
            {
                "RelationID": 27,
                "Relation1": "SON-IN-LAW"
            },
            {
                "RelationID": 28,
                "Relation1": "SECONDARY SPOUSE"
            },
            {
                "RelationID": 29,
                "Relation1": "STEP CHILD"
            }
        ];
    let relationOptions: { value: string; label: string; }[] = []
    relations.sort((relation1, relation2) => relation1.RelationID - relation2.RelationID)
    relations.forEach(relation => relationOptions.push({value: relation.Relation1, label: relation.Relation1}))
    return relationOptions;
}

export function getDependentRelations() {
    const relations =
        [
            {
                "RelationID": 4,
                "Relation1": "CHILD"
            },
            {
                "RelationID": 17,
                "Relation1": "SPOUSE"
            },
            {
                "RelationID": 19,
                "Relation1": "EXTENDED"
            },
        ];
    let relationOptions: { value: string; label: string; }[] = []
    relations.sort((relation1, relation2) => relation1.RelationID - relation2.RelationID)
    relations.forEach(relation => relationOptions.push({value: relation.Relation1, label: relation.Relation1}))

    return relationOptions;
}

export function getCountries() {
    const countries = [
        {
            "CountryID": 5,
            "Continent": "Africa",
            "CountryName": "Angola",
            "Currency": "USD"
        },
        {
            "CountryID": 23,
            "Continent": "Africa",
            "CountryName": "Botswana",
            "Currency": "USD"
        },
        {
            "CountryID": 169,
            "Continent": "Africa",
            "CountryName": "Eswatini",
            "Currency": "USD"
        },
        {
            "CountryID": 97,
            "Continent": "Africa",
            "CountryName": "Lesotho",
            "Currency": "USD"
        },
        {
            "CountryID": 105,
            "Continent": "Africa",
            "CountryName": "Malawi",
            "Currency": "USD"
        },
        {
            "CountryID": 120,
            "Continent": "Africa",
            "CountryName": "Mozambique",
            "Currency": "USD"
        },
        {
            "CountryID": 122,
            "Continent": "Africa",
            "CountryName": "Namibia",
            "Currency": "USD"
        },
        {
            "CountryID": 163,
            "Continent": "Africa",
            "CountryName": "South Africa",
            "Currency": "ZAR"
        },
        {
            "CountryID": 175,
            "Continent": "Africa",
            "CountryName": "Tanzania",
            "Currency": "USD"
        },
        {
            "CountryID": 196,
            "Continent": "Africa",
            "CountryName": "Zambia",
            "Currency": "USD"
        },
        {
            "CountryID": 197,
            "Continent": "Africa",
            "CountryName": "Zimbabwe",
            "Currency": "USD"
        }
    ];

    countries.sort((country1, country2) => country1.CountryID - country2.CountryID)
    let countryOptions: { value: string; label: string; }[] = []
    countries.forEach(relation => countryOptions.push({value: relation.CountryName, label: relation.CountryName}))

    return countryOptions;
}

export function areaFilter(area: Area): boolean {
    const countries = getCountries();
    let filtered: boolean = area.name!.includes(countries[0].value);

    for (let i = 1; i < countries.length; i++) {
        filtered = filtered || area.name!.includes(countries[i].value);
    }

    return filtered
}



