import React from 'react'
import images from '../../assets/images'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { AiFillTwitterCircle } from "react-icons/ai";


export const HeaderComponent: React.FC = () => {
    return (
        <header className="at-header">
            <div className="at-topbar">
                <div className="at-container">
                    <div className="at-topbar-area">
                        <ul className="at-contectandemil">
                            <li>
                                <a href="mailto:info@nyaradzo.co.za">
                                    <i className="icon-mail"></i>
                                    info@nyaradzo.co.za   
                                </a>
                            </li>
                            <li>
                                <a href="http://www.google.com">
                                    <i className="icon-phone"></i>
                                    +27 87 365 3221   
                                </a>
                            </li>
                        </ul>
                        <ul className="at-socialicons">
                            <li>
                                <a href="http://www.google.com">
                                    <i className="icon-whatsapp"></i>
                                </a>
                            </li>
                            <li>
                                <a href="http://www.google.com">
                                    <i className="icon-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="http://www.google.com">
                                    <AiFillTwitterCircle/>
                                </a>
                            </li>
                            <li>
                                <a href="http://www.google.com">
                                    <i className="icon-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="at-mainheader">
                <div className="at-container">
                    <div className="at-mainheaderarea">
                        <strong className="at-logo">
                            <Link to="/">
                                <img src={images.logo} alt=""/>
                            </Link>
                        </strong>
                        <div className="at-navarea">
                            <Navbar expand="lg" className="at-navbar">
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="at-navigation">
                                    <Nav className="at-nav">
                                        <Link to="/" className="active">HOME</Link>
                                        <a href="https://nyaradzo.co.za/funeral-cover/">INSURANCE</a>
                                        <a href="https://nyaradzo.co.za/about-us/">ABOUT</a>
                                        <a href="https://nyaradzo.co.za/funeral-services/">FAQS</a>
                                        <a href="https://nyaradzo.co.za/contact-us/">CONTACT US</a>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                            <Link to="/onboarding" className="at-btn">
                                <i className="icon-file"></i>
                                Get Started
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
