import React from 'react'
import images from '../../assets/images'
import {Link} from 'react-router-dom'


export const FooterComponent: React.FC = () => {
    return (
        <footer className="at-footer">
            <div className="at-footer-topbar">
                <div className="at-container">
                    <div className="at-topbar-area">
                        <p>Get more <strong>CHOICE</strong> and more <strong>COVER</strong> for the ones you <strong>VALUE</strong> most!</p>
                        <span className="at-number"><i className="icon-phone"></i> +27 87 365 3221</span>
                    </div>           
                </div>           
            </div>
            <div className="at-footermain">
                <div className="at-container">
                    <div className="at-footermain-area">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                <div className="at-about">
                                    <strong className="at-footerlogo">
                                        <Link to="/">
                                            <img src={images.logo} alt=""/>
                                        </Link>
                                    </strong>
                                    <div className="at-description">
                                        <p>We Listen, Understand and Provide Solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                <div className="at-footerlinks">
                                    <div className="at-heading">
                                        <h2>Nyaradzo</h2>
                                    </div>
                                    <ul className="at-likslist">
                                        <li><a href="http://www.google.com">Services</a></li>
                                        <li><a href="http://www.google.com">Benefits</a></li>
                                        <li><a href="http://www.google.com">Cards plan</a></li>
                                        <li><a href="https://nyaradzo.co.za/funeral-services/">Faqs</a></li>
                                        <li><a href="https://nyaradzo.co.za/about-us/">About</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                <div className="at-information">
                                    <div className="at-heading">
                                        <h2>Official info:</h2>
                                    </div>
                                    <ul className="at-informationlist">
                                        <li><i className="icon-location-mark"></i> <span> 0A Standard Close, Cnr Langwa & Hammer Rd, Strydom Park, Randburg, Johannesburg.</span></li>
                                        <li><i className="icon-phone"></i> <span> +27 87 365 3221 </span></li>
                                        <li className="at-openinghours">
                                            <h3>Open Hours:</h3>

                                            Mon – Sat: 8 am – 5 pm, Sunday: CLOSED
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                                <div className="at-newslatter">
                                    <div className="at-heading">
                                        <h2>Newsletter</h2>
                                    </div>
                                    <div className="at-description">
                                        <p>Subscribe our newsletter to get our latest update & news </p>
                                    </div>
                                    <form className="at-themeform">
                                        <div className="at-inputwithbtn">
                                            <input type="text" className="form-control" placeholder="Your email address" />
                                            <button type="button" className="at-sendbtn"><i className="icon-send"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="at-footer-bottombar">
                <span>2021 c - Developed by IT Savanna</span>
            </div>           
        </footer>
    )
}