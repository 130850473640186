import {CardDetails} from "./card-details";
import {MemberDetails} from "./member-details";
import {Quote} from "./quote";

export class Application {

    card: CardDetails = {
        cardType: "",
        payAtNumber: "",
        policyNumber: "",
        verified: false,
        verifiedOn: null,

    };

    member: MemberDetails = {
        title: "",
        mobileNumber: {
            code: 27,
            phone: "",
            short: "",
        },
        mobileNumberVerified: false,
        dateOfBirth: null,
        email: "",
        firstName: "",
        idNumber: "",
        lastName: "",
        passportNumber: "",
        nationality: "",
        maritalStatus: "",
        relationship: "",
        gender: "",
        street: "",
        city: "",
        province: "",
        country: "",
        isDependentBasedInZimbabwe: false
    }
    benefits: string[] = [];
    dependants: MemberDetails[] = [];
    beneficiaries: MemberDetails[] = [];
    quote: Quote | null = null;
    locked: boolean = false;

}
