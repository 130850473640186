import React from 'react'
import {Button} from 'antd';
import {IProps} from "../../../model/iprops";
import images from "../../../assets/images";
import {FilePdfOutlined, SendOutlined} from "@ant-design/icons";

interface ComponentState {
}

export class CongratulationsComponent extends React.Component<IProps, ComponentState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            processing: false
        };
    }

    render() {
        return (
            <div className="at-contentarea">
                <div className="at-sectionhead">
                    <h2>Congratulations</h2>
                    <div className="at-description">
                        <p>Your policy is now active</p>
                    </div>
                </div>
                <div className="at-contentbox">
                    <div className="at-congratulationcard">
                        <div className="at-contentbox">
                            <div className="at-heading">
                                <h3>Policy</h3>
                            </div>
                            <div className="at-description">
                                <p> Congratulations your policy is now active.<br/>
                                    However to ensure you are covered make your first premium payment<br/>
                                    Click on Save button to download your policy document for printing or email. <br/>
                                    Keep the documents and your card for any Enquiries you may have. Thank you</p>
                            </div>
                        </div>
                        <div className="at-congratulationimage">
                            <img src={images.congratulationimg} alt=""/>
                        </div>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col">
                        <Button type="primary"
                                size="large"
                                shape="round"
                                icon={<FilePdfOutlined />}>
                            Save as PDF
                        </Button>
                    </div>
                    <div className="col">
                        <Button type="primary"
                                size="large"
                                shape="round"
                                className="zright"
                                icon={<SendOutlined />}
                                href={`https://payat.io/qr/${this.props.application.card.payAtNumber}`}>
                            Pay Premium
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}    
