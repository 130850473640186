import React from 'react'
import { Layout } from '../components/layout'
import { BannerComponent } from '../components/shared'

export const VirtualCardPage : React.FC= () => {


    return (
        <Layout>
            <BannerComponent />
            <main className="at-main at-sectionspace">
                {/* ************************************
                            Virtual Card Pgae Start
                ************************************* */}
                <section className="at-virtualcard at-sectionspace">
                    <div className="at-container">
                        <div className="at-sectionhead">
                            <span>Virtual Card</span>
                            <div className="at-description">
                                <p>To create a new virtual card please capture your details below and  <br/> you will receive details of your Virtual Card via SMS and E-Mail</p>
                            </div>
                        </div>
                        <div className="at-contentbox at-cardfromcontent">
                            <form className="at-themeform">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>First Name</label>
                                            <input type="text" className="form-control" placeholder="Alex" />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Surname</label>
                                            <input type="text" className="form-control" placeholder="Brandon" />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Cellphone Number</label>
                                            <input type="text" className="form-control" placeholder="xxxxxxxx" />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" placeholder="Alex@mail.com" />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label>Location</label>
                                            <input type="text" className="form-control" placeholder="London, United Kingdom" />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">                                        
                                        <label>Your Date of Birth</label>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                                <div className="form-group">
                                                    <div className="at-select">
                                                        <select>
                                                            <option>Date</option>
                                                            <option>01</option>
                                                            <option>02</option>
                                                            <option>03</option>
                                                            <option>04</option>
                                                            <option>05</option>
                                                            <option>06</option>
                                                            <option>07</option>
                                                            <option>08</option>
                                                            <option>09</option>
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                            <option>13</option>
                                                            <option>14</option>
                                                            <option>15</option>
                                                            <option>16</option>
                                                            <option>17</option>
                                                            <option>18</option>
                                                            <option>19</option>
                                                            <option>20</option>
                                                            <option>21</option>
                                                            <option>22</option>
                                                            <option>23</option>
                                                            <option>24</option>
                                                            <option>25</option>
                                                            <option>26</option>
                                                            <option>27</option>
                                                            <option>28</option>
                                                            <option>29</option>
                                                            <option>30</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                                <div className="form-group">
                                                    <div className="at-select">
                                                        <select>
                                                            <option>Month</option>
                                                            <option>01</option>
                                                            <option>02</option>
                                                            <option>03</option>
                                                            <option>04</option>
                                                            <option>05</option>
                                                            <option>06</option>
                                                            <option>07</option>
                                                            <option>08</option>
                                                            <option>09</option>
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                                                <div className="form-group">
                                                    <div className="at-select">
                                                        <select>
                                                            <option>Year</option>
                                                            <option>2021</option>
                                                            <option>2020</option>
                                                            <option>2019</option>
                                                            <option>2018</option>
                                                            <option>2017</option>
                                                            <option>2016</option>
                                                            <option>2015</option>
                                                            <option>2014</option>
                                                            <option>2013</option>
                                                            <option>2012</option>
                                                            <option>2011</option>
                                                            <option>2010</option>
                                                            <option>2009</option>
                                                            <option>2008</option>
                                                            <option>2007</option>
                                                            <option>2006</option>
                                                            <option>2005</option>
                                                            <option>2004</option>
                                                            <option>2003</option>
                                                            <option>2002</option>
                                                            <option>2001</option>
                                                            <option>2000</option>
                                                            <option>1999</option>
                                                            <option>1998</option>
                                                            <option>1997</option>
                                                            <option>1996</option>
                                                            <option>1995</option>
                                                            <option>1994</option>
                                                            <option>1993</option>
                                                            <option>1992</option>
                                                            <option>1991</option>
                                                            <option>1990</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">                                        
                                        <div className="form-group">
                                            <label className="at-customcheckbox">
                                                <input type="checkbox" className="form-control" />
                                                <span className="at-checkmark"></span>
                                                Allow customer services to contact you
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="at-btnsarea">
                                    <button type="button" className="at-btn ml-auto">submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}