import React from 'react'
import images from '../assets/images';
import { Layout } from '../components/layout'
import { BannerSliderComponent } from '../components/shared'
import Carousel from "react-multi-carousel";
import {Link} from 'react-router-dom'
import "react-multi-carousel/lib/styles.css";


export const HomePage : React.FC= () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 3,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1199, min: 768 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };

    return (
        <Layout>
            <BannerSliderComponent />
            <main className="at-main at-sectionspace">
                {/* ************************************
                            Services Start
                ************************************* */}
                <section id="at-services" className="at-services at-sectionspace">
                    <div className="at-container">
                        <div className="at-sectionhead">
                            <span>Services</span>
                            <h2>Affordable Funeral <br/> Cover</h2>
                            <div className="at-description">
                                <p>Providing affordable Funeral Cover for both local and those <br/> in the diaspora. We can repatriate to anywhere in the <br/> world.</p>
                            </div>
                        </div>
                        <div className="at-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="at-serverbox">
                                        <div className="at-heading">
                                            <h3>Repatriation of <br/> the deceased to Zimbabwe</h3>
                                        </div>
                                        <div className="at-servercontent">
                                            <div className="at-iconbox">
                                                <i className="icon-airplane"></i>
                                            </div>
                                            <ul className="at-servicelist">
                                                <li>Body removal from place of death to mortuary</li>
                                                <li>International embalming and body preparation</li>
                                                <li>Provision of hermetically sealed coffin suitable
                                                    for international transportation</li>
                                                <li>Assistance with documentation processing</li>
                                                <li>Grocery allowance</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="at-serverbox">
                                        <div className="at-heading">
                                            <h3>Funeral <br/> Service in Zimbabwe</h3>
                                        </div>
                                        <div className="at-servercontent">
                                            <div className="at-iconbox">
                                                <i className="icon-funeral"></i>
                                            </div>
                                            <ul className="at-servicelist">
                                                <li>Chapel services (where a chapel is available)</li>
                                                <li>Hearse to place of burial in Zimbabwe</li>
                                                <li>Bus transport for mourners to place of burial</li>
                                                <li>Graveside equipment</li>
                                                <li>Provision of a 200 seater tent and chairs along with 2 portable toilets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ************************************
                            About us Start
                ************************************* */}
                <section id="at-aboutus" className="at-aboutus at-sectionspace">
                    <div className="at-container">
                        <div className="at-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="at-aboutimage">
                                        <figure>
                                            <img src={images.aboutimg} alt=""/>
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="at-aboutcontent">
                                        <div className="at-heading">
                                            <h3>Worried About The Costs Of Caring For Your Loved Ones At Death? We Have You Covered!</h3>
                                        </div>
                                        <div className="at-description">
                                            <p>It's as easy as getting your card from a designated outlet and enrolling yourself for cover in event of death.  For the  nearest location please click on the button below.</p>   
                                        </div>
                                        <div className="at-btnarea">
                                            <Link to="/locations" className="at-btn"><i className="icon-file"></i>Nearest Outlet</Link>
                                            <Link to="/virtualcard" className="at-btn at-primarybtn"><i className="icon-file"></i>Get a Virtual Card</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ************************************
                            Benefits Start
                ************************************* */}
                <section id="at-benefits" className="at-benefits at-sectionspace">
                    <div className="at-container">
                        <div className="at-sectionhead">
                            <span>Benefits</span>
                            <h2>Add Optional Benefits To <br/> Your Plan</h2>
                            <div className="at-description">
                                <p>These optional benefits will give you a peace of mind for <br/> tomorrow.</p>
                            </div>
                        </div>
                        <div className="at-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="at-benefitbox">
                                        <div className="at-iconbox">
                                            <i className="icon-tickets"></i>
                                        </div>
                                        <div className="at-heading">
                                            <h3>2 Return Air Tickets</h3>
                                        </div>
                                        <div className="at-description">
                                            <p>Receive two return air tickets for travel from your country of residence to Zimbabwe upon death of a life assured. The thinking behind this benefit is that death comes when least expected and in our culture, it is the norm to be present for a family burial whether immediate or extended.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="at-benefitbox">
                                        <div className="at-iconbox">
                                            <i className="icon-bed"></i>
                                        </div>
                                        <div className="at-heading">
                                            <h3>Accommodation in Zimbabwe</h3>
                                        </div>
                                        <div className="at-description">
                                            <p>As an organisation, we understand that when Diasporans come home to be together with their family during a bereavement, they still want the comfort that they are used to abroad. We provide fully furnished, self-catering two bedroom apartments</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                    <div className="at-benefitbox">
                                        <div className="at-iconbox">
                                            <i className="icon-car"></i>
                                        </div>
                                        <div className="at-heading">
                                            <h3>Vehicle</h3>
                                        </div>
                                        <div className="at-description">
                                            <p>More often than not when one returns
                                                to Zimbabwe for a funeral, finding a
                                                vehicle to use can be a challenge. Our
                                                aim is to offer you the highest level of
                                                convenience by ensuring that you have
                                                the use of a reliable and well maintained
                                                double cab vehicle for the duration of
                                                your stay which is capped at 7 days.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ************************************
                            Card Plan Start
                ************************************* */}
                <section className="at-cardplan at-sectionspace">
                    <div className="at-container">
                        <div className="at-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <figure className="at-cardplanimg">
                                        <img src={images.cardplan} alt=""/>
                                    </figure>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="at-cardplancontent">
                                        <div className="at-sectionhead">
                                            <span>Cards Plan</span>
                                            <h2>Choose The Right Cover <br/> For You:</h2>
                                            <div className="at-description">
                                                <p>We have a variety of options to choose from to your every need.</p>
                                            </div>
                                        </div>
                                        <ul className="at-planlist">
                                            <li>
                                                <span className="at-iconbox">
                                                    <img src={images.goldpln} alt=""/>
                                                </span>
                                                <h5>Gold Plan</h5>
                                            </li>
                                            <li>
                                                <span className="at-iconbox">
                                                    <img src={images.diamondlan} alt=""/>
                                                </span>
                                                <h5>Diamond Plan</h5>
                                            </li>
                                            <li>
                                                <span className="at-iconbox">
                                                    <img src={images.platinumplan} alt=""/>
                                                </span>
                                                <h5>Platinum Plan</h5>
                                            </li>
                                            <li>
                                                <span className="at-iconbox">
                                                    <img src={images.premierplan} alt=""/>
                                                </span>
                                                <h5>Premier Plan</h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* ************************************
                            Testimonials Start
                ************************************* */}
                <section className="at-testimonials at-sectionspace">
                    <div className="at-container">
                        <div className="at-sectionhead">
                            <span>Testimonials</span>
                            <h2>We Serve Our Clients With The <br/> Best Of Our Capacity</h2>
                        </div>
                        <div className="at-content">
                            <Carousel
                                swipeable={false}
                                draggable={true}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={false}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                // deviceType={props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                className="at-testimonial-slider"
                                >
                                <div className="at-item">
                                    <div className="at-testimonialbox">
                                        <div className="at-description">
                                            <p>I would like to take this opportunity to thank you Nyaradzo for all your assistance and hard work in repatriating my loved one's remains back home . You were very patient and always willing to answer all questions .Your customer Service is great.</p>
                                        </div>
                                        <span className="at-stars">
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="at-testimonialcontent">
                                        <figure className="at-testimonialimage">
                                            <img src={images.user1} alt=""/>
                                        </figure>
                                        <div className="at-testimonialtitle">
                                            <h3>Wadzanai Melz</h3>
                                            <span>Client</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="at-item">
                                    <div className="at-testimonialbox">
                                        <div className="at-description">
                                            <p>Nyaradzo Funeral Services were excellent with my father’s funeral and all related services. They provided superb service and customer care. Nyaradzo staff were professional, caring and thoughtful. Thank you for doing such a great job.</p>
                                        </div>
                                        <span className="at-stars">
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="at-testimonialcontent">
                                        <figure className="at-testimonialimage">
                                            <img src={images.user2} alt=""/>
                                        </figure>
                                        <div className="at-testimonialtitle">
                                            <h3>Ashleigh</h3>
                                            <span>Client</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="at-item">
                                    <div className="at-testimonialbox">
                                        <div className="at-description">
                                            <p>We were happy with the Nyaradzo services during the recent funeral of our dear brother. We would like to thank the Nyaradzo team for excellent service during the arrangements to take our brother home.</p>
                                        </div>
                                        <span className="at-stars">
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="at-testimonialcontent">
                                        <figure className="at-testimonialimage">
                                            <img src={images.user3} alt=""/>
                                        </figure>
                                        <div className="at-testimonialtitle">
                                            <h3>Munyaradzi</h3>
                                            <span>Client</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="at-item">
                                    <div className="at-testimonialbox">
                                        <div className="at-description">
                                            <p>I would like to take this opportunity to thank you Nyaradzo for all your assistance and hard work in repatriating my loved one's remains back home . You were very patient and always willing to answer all questions .Your customer Service is great.</p>
                                        </div>
                                        <span className="at-stars">
                                            <span></span>
                                        </span>
                                    </div>
                                    <div className="at-testimonialcontent">
                                        <figure className="at-testimonialimage">
                                            <img src={images.user4} alt=""/>
                                        </figure>
                                        <div className="at-testimonialtitle">
                                            <h3>Wadzanai Melz</h3>
                                            <span>Client</span>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}