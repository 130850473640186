import {Route, Switch} from 'react-router-dom'

import {HomePage, LocationsPage, VirtualCardPage} from './pages';
import {OnboardingComponent} from "./pages/onboarding-component";

function App() {
  return (
    <>
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/locations" exact component={LocationsPage} />
      <Route path="/virtualcard" exact component={VirtualCardPage} />
      <Route path="/onboarding" exact component={OnboardingComponent} />
    </Switch>
  </>
  );
}

export default App;
