import React, {ChangeEvent} from 'react'
import {
    Alert,
    AutoComplete,
    Button,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Modal,
    Radio,
    RadioChangeEvent,
    Select,
    Space
} from 'antd';
import {IProps} from "../../../model/iprops";
import {MemberDetails} from "../../../model/member-details";
import axios from "axios";
import moment from 'moment';
import {AiFillCheckCircle} from "react-icons/all";
import {ExclamationCircleFilled, TeamOutlined} from "@ant-design/icons";
import {areaFilter, getCountries, getGenderOptions, getMaritalStatusOptions, getTitles} from "../../../model/helpers";
import CountryPhoneInput, {ConfigProvider, CountryPhoneInputValue} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

interface ComponentState {
    member: MemberDetails
    policyNumber: string
    passportNumber: string
    idNumber: string
    originalMobileNumber: CountryPhoneInputValue,
    processing: boolean
    otp: {
        visible: boolean
        processing: boolean
        code: string
        failed: boolean
    },
    failureAlert: boolean,
    failureTitle: string,
    failureBody: string,
    verificationFailCount: number

}

const genderOptions = getGenderOptions()
const maritalStatusOptions = getMaritalStatusOptions()
const titles = getTitles()
const countries = getCountries()

export class MainMemberComponent extends React.Component<IProps, ComponentState> {
    constructor(props: IProps) {
        super(props);
        let member = props.application.member;
        let card = props.application.card
        this.state = {
            member: member,
            policyNumber: card.policyNumber,
            originalMobileNumber: member.mobileNumber,
            passportNumber: member.passportNumber,
            idNumber: member.idNumber,
            processing: false,
            otp: {
                visible: false,
                processing: false,
                code: "",
                failed: false
            },
            failureAlert: false,
            failureTitle: "",
            failureBody: "",
            verificationFailCount: 0
        };
    }

    formRef = React.createRef<FormInstance>();

    disabledDate = (current: any) => {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }

    onGenderSelect = (e: RadioChangeEvent) => {
        var currentState = {...this.state}
        currentState.member.gender = e.target.value;
        this.setState(currentState)
    };

    onDOBChange = (date: any, dateString: string) => {
    };

    onPassportNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
                ...this.state,
                passportNumber: e.target.value
            }
        )
    };

    onOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
        let otp = this.state.otp
        otp.code = e.target.value;
        this.setState({
            otp: otp
        })
    };

    onIDNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
                ...this.state,
                idNumber: e.target.value
            }
        )
    };

    onFinish = (values: any) => {
        this.setState({
            member: {
                ...values
            },
            processing: true
        });

        if (this.state.member.mobileNumber.code !== this.state.originalMobileNumber.code ||
            this.state.member.mobileNumber.phone !== this.state.originalMobileNumber.phone ||
            this.state.member.mobileNumber.short !== this.state.originalMobileNumber.short) {
            this.generateCode(true)
        } else {
            this.setState({
                ...this.state,
                processing: false
            });

            let member = this.state.member
            member.mobileNumberVerified = true
            this.props.next(member)
        }
    }

    generateCode = (verify: boolean) => {
        this.setState({
            ...this.state,
            verificationFailCount: 0
        });
        let cleaned_phone_number = this.state.member.mobileNumber.phone
        if (cleaned_phone_number) {
            if (cleaned_phone_number.length > 9) {
                cleaned_phone_number = cleaned_phone_number.substring(11 - cleaned_phone_number.length)
            }

            let body = {
                "mobileNumber": "+" + this.state.member.mobileNumber.code + cleaned_phone_number,
                "policyNumber": +this.state.policyNumber
            }

            axios.post("https://api.nyaradzo.sahwi.net/phone/validation", body).then((response) => {

                if (verify) {
                    let otp = this.state.otp
                    otp.visible = true;
                    this.setState({
                        ...this.state,
                        processing: false,
                        otp: otp
                    });
                } else {
                    this.setState({
                        ...this.state,
                        processing: false
                    });
                }
            }).catch(error => {
                this.setState({
                    ...this.state,
                    processing: false,
                    verificationFailCount: this.state.verificationFailCount + 1,
                    failureAlert: true,
                    failureTitle: "SMS Verification Failed",
                    failureBody: "A failure occurred verifying your phone, kindly try again later.",
                });
            })
        }
    }

    onFinishFailed = (errorInfo: any) => {
        this.setState({
            ...this.state,
            processing: false
        });
        Modal.error({
            title: 'Submission Failed',
            content: (
                <div>You have errors in your submission. Check the form for errors, fix and resubmit.</div>
            ),
            onOk() {},
        });
    };

    back = () => {
        let values = this.formRef.current?.getFieldsValue()
        this.props.prev(values)
    }

    handleGenerateCode = () => {
        this.generateCode(false)
    }

    handleOk = () => {
        let otp = this.state.otp
        otp.processing = true;
        otp.failed = false;
        this.setState({
            ...this.state,
            otp: otp
        })
        let validated_phone_number = this.state.member.mobileNumber.phone?.length!! > 9 ? this.state.member.mobileNumber.phone?.substring(1) : this.state.member.mobileNumber.phone
        let body = {
            "mobileNumber": `+${this.state.member.mobileNumber.code}${validated_phone_number}`,
            "code": this.state.otp.code
        }
        axios.put("https://api.nyaradzo.sahwi.net/phone/validation", body).then((response) => {
            let otp = this.state.otp
            otp.visible = false
            otp.processing = false;
            this.setState({
                ...this.state,
                otp: otp
            })

            let member = this.state.member
            member.mobileNumberVerified = true
            this.props.next(member)
        }).catch(error => {
            let otp = this.state.otp
            otp.failed = true;
            otp.processing = false;
            this.setState({
                ...this.state,
                processing: false,
                otp: otp,
                verificationFailCount: this.state.verificationFailCount + 1,
                failureAlert: true,
                failureTitle: "Invalid Code Supplied",
                failureBody: "Check your code or resend to get a new code.",
            });
        });

    };

    handleCancel = () => {
        let otp = this.state.otp
        otp.visible = false;
        this.setState({
            ...this.state,
            otp: otp
        })
    };

    render() {
        const {TextArea} = Input;

        return (
            <div className="at-contentarea">
                <div className="at-sectionhead">
                    <h2>Main Member Details</h2>
                    <div className="at-description">
                        <p>Please enter the member's details to proceed.</p>
                    </div>
                </div>
                <div className="at-contentbox at-cardfromcontent">
                    {this.state.failureAlert &&

                        <div className="row">
                            <div className="col">
                                <Alert
                                    message={this.state.failureTitle}
                                    description={this.state.failureBody}
                                    type="error"
                                    showIcon
                                />
                                <br/>
                            </div>

                        </div>
                    }
                    <ConfigProvider locale={en}
                                    areaFilter={(area) => areaFilter(area)}>
                        <Form
                            name="basic"
                            layout="vertical"
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            autoComplete="off"
                            ref={this.formRef}
                        >
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="First Name"
                                            name="firstName"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.firstName}
                                            rules={[{required: true, message: 'Please provide your first name!'}]}
                                        >
                                            <Input disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Last Name"
                                            name="lastName"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.lastName}
                                            rules={[{required: true, message: 'Please provide your last name!'}]}
                                        >
                                            <Input disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Title"
                                            labelAlign="left"
                                            name="title"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.title}
                                            rules={[{required: true, message: 'Please select your title!'}]}
                                        >
                                            <Select size="large" options={titles}
                                                    disabled={this.props.application.locked}>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Gender"
                                            name="gender"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.gender}
                                            rules={[{required: true, message: 'Please select your gender!'}]}
                                        >
                                            <Radio.Group
                                                options={genderOptions}
                                                value={this.state.member.gender}
                                                onChange={this.onGenderSelect}
                                                optionType="button"
                                                buttonStyle="solid"
                                                size="large"
                                                disabled={this.props.application.locked}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label={"Date of Birth"}
                                            name="dateOfBirth"
                                            valuePropName={'date'}
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.dateOfBirth}
                                            rules={[{required: true, message: 'Please provide your date of birth!'}]}
                                        >
                                            {this.state.member.dateOfBirth ?
                                                <DatePicker
                                                    defaultValue={this.state.member.dateOfBirth}
                                                    style={{width: '100%'}}
                                                    onChange={this.onDOBChange}
                                                    disabledDate={this.disabledDate}
                                                    mode="date"
                                                    format="DD MMMM YYYY"
                                                    size="large"
                                                    disabled={this.props.application.locked}/> :
                                                <DatePicker
                                                    style={{width: '100%'}}
                                                    onChange={this.onDOBChange}
                                                    disabledDate={this.disabledDate}
                                                    mode="date"
                                                    format="DD MMMM YYYY"
                                                    size="large"
                                                    disabled={this.props.application.locked}/>
                                            }
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Marital Status"
                                            name="maritalStatus"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.maritalStatus}
                                            rules={[{required: true, message: 'Please select your marital status!'}]}
                                        >
                                            <Select size="large" options={maritalStatusOptions}
                                                    disabled={this.props.application.locked}>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Nationality"
                                            name="nationality"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.nationality}
                                            rules={[{required: true, message: 'What is your nationality!'}]}
                                        >
                                            <AutoComplete
                                                size="large"
                                                options={countries}
                                                disabled={this.props.application.locked}
                                                filterOption={(inputValue, option) =>
                                                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="ID Number"
                                            name="idNumber"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.idNumber}
                                            rules={[{
                                                required: this.state.passportNumber.length === 0,
                                                message: 'Please provide your ID or passport number!'
                                            }]}
                                        >
                                            <Input onChange={this.onIDNumberChange}
                                                   disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Passport Number"
                                            name="passportNumber"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.passportNumber}
                                            rules={[{
                                                required: this.state.idNumber.length === 0,
                                                message: 'Please provide your passport or ID number!'
                                            }]}
                                        >
                                            <Input onChange={this.onPassportNumberChange}
                                                   disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Mobile Number"
                                            name="mobileNumber"
                                            validateTrigger='onSubmit'
                                            initialValue={{...this.state.member.mobileNumber}}
                                            rules={[{required: true, message: 'Please provide a phone number!'}]}
                                        >
                                            <CountryPhoneInput
                                                disabled={this.props.application.locked}
                                                suffix={this.state.member.mobileNumberVerified ?
                                                    <AiFillCheckCircle style={{color: 'green'}}
                                                                       className="site-form-item-icon"/> : ''}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Email Address"
                                            name="email"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.email}
                                            rules={[{
                                                required: false,
                                                type: "email",
                                                message: "Please provide a valid email address!",
                                            }]}
                                        >
                                            <Input disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h3>Address Details</h3>
                                    <hr/>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Street Address"
                                            name="street"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.street}
                                            rules={[{required: true, message: 'Please provide your street address!'}]}
                                        >
                                            <TextArea rows={4} disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <Form.Item
                                            label="City"
                                            name="city"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.city}
                                            rules={[{required: true, message: 'Which city do you live in!'}]}
                                        >
                                            <Input disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Province"
                                            name="province"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.province}
                                            rules={[{required: true, message: 'What is your province of residence!'}]}
                                        >
                                            <Input disabled={this.props.application.locked}/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Country"
                                            name="country"
                                            validateTrigger='onSubmit'
                                            initialValue={this.state.member.country}
                                            rules={[{required: true, message: 'What is your country of residence!'}]}
                                        >
                                            <AutoComplete
                                                disabled={this.props.application.locked}
                                                size="large"
                                                options={countries}
                                                filterOption={(inputValue, option) =>
                                                    option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>


                            <Form.Item
                                name="mobileNumberVerified"
                                initialValue={this.state.member.mobileNumberVerified}
                            >
                                <Input type="hidden"/>
                            </Form.Item>

                            <Form.Item
                                name="relationship"
                                initialValue={this.state.member.relationship}
                            >
                                <Input type="hidden"/>
                            </Form.Item>

                            <Form.Item
                                name="isDependentBasedInZimbabwe"
                                initialValue={this.state.member.isDependentBasedInZimbabwe}
                            >
                                <Input type="hidden"/>
                            </Form.Item>

                            <div className="row">
                                <div className="col">
                                    <Button type="primary"
                                            size="large"
                                            shape="round"
                                            htmlType="submit"
                                            className="zright"
                                            icon={<TeamOutlined />}
                                            loading={this.state.processing}>
                                        Next
                                    </Button>
                                </div>
                            </div>

                        </Form>
                    </ConfigProvider>
                </div>

                <Modal
                    visible={this.state.otp.visible}
                    confirmLoading={this.state.otp.processing}
                    footer={null}
                    closable={false}
                >
                    {this.state.verificationFailCount > 0 &&
                        <div className="row">
                            <div className="col">
                                <Alert
                                    message="Verify Phone Number"
                                    description={"The supplied one time password is incorrect. Please recheck and provide the one time password sent to +" + this.state.member.mobileNumber.code +
                                        (this.state.member.mobileNumber.phone?.length!! > 9 ? this.state.member.mobileNumber.phone?.substring(1) : this.state.member.mobileNumber.phone)
                                        + " or generate a new password"}
                                    type="error"
                                    showIcon
                                />
                                <br/>
                            </div>

                        </div>
                    }

                    {this.state.verificationFailCount === 0 &&

                        <div className="row">
                            <div className="col">
                                <Alert
                                    message="Verify Phone Number"
                                    description={"Please provide the one time password sent to +" + this.state.member.mobileNumber.code +
                                        (this.state.member.mobileNumber.phone?.length!! > 9 ? this.state.member.mobileNumber.phone?.substring(1) : this.state.member.mobileNumber.phone)
                                    }
                                    type="info"
                                    showIcon
                                />
                                <br/>
                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col">
                            <Form.Item
                                name="otp"
                                validateTrigger='onSubmit'
                                rules={[{
                                    required: true,
                                    pattern: new RegExp(/^[0-9]+$/),
                                    message: 'Please input the one time password!'
                                }
                                ]}
                            >
                                <Input suffix={this.state.otp.failed ?
                                    <ExclamationCircleFilled
                                        style={{color: 'red'}}
                                        className="site-form-item-icon"/> : ''}
                                       status={this.state.otp.failed ? 'error' : ''}
                                       onChange={this.onOtpChange}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Space>
                                <Button key="back"
                                        size="large"
                                        shape="round"
                                        disabled={this.state.otp.processing}
                                        onClick={this.handleCancel}>
                                    Cancel
                                </Button>

                                <Button
                                    key="link"
                                    type="primary"
                                    size="large"
                                    shape="round"
                                    disabled={this.state.otp.processing}
                                    onClick={this.handleGenerateCode}
                                >
                                    Generate New Code
                                </Button>

                                <Button key="process"
                                        type="primary"
                                        size="large"
                                        shape="round"
                                        loading={this.state.otp.processing}
                                        onClick={this.handleOk}>
                                    Verify Code
                                </Button>
                            </Space>
                        </div>
                    </div>


                </Modal>
            </div>


        )
    }
}
