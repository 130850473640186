import React from 'react'
import images from '../assets/images';
import { Layout } from '../components/layout'
import { BannerComponent } from '../components/shared'

export const LocationsPage : React.FC= () => {


    return (
        <Layout>
            <BannerComponent />
            <main className="at-main at-sectionspace">
                {/* ************************************
                            Location Pgae Start
                ************************************* */}
                <section className="at-locations at-sectionspace">
                    <div className="at-container">
                        <div className="at-sectionhead">
                            <span>Your Nearest Locations</span>
                            <div className="at-description">
                                <p>Below are the locations where you can go <br/> and get your card.</p>
                            </div>
                        </div>
                        <div className="at-content">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                                    <div className="at-locationcontent">
                                        <div className="at-locationbox">
                                            <figure >
                                                <img src={images.cardCheckers} alt=""/>
                                            </figure>
                                          
                                        </div>
                                        <div className="at-locationbox">
                                            <figure>
                                                <img src={images.cardPickNPay} alt=""/>
                                            </figure>
                                           
                                        </div>
                                        <div className="at-locationbox">
                                            <figure >
                                                <img src={images.cardShoprite} alt=""/>
                                            </figure>
                                            
                                        </div>
                                        <div className="at-locationbox">
                                            <figure >
                                                <img src={images.cardSpar} alt=""/>
                                            </figure>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                                    <div className="at-locationmap">
                                        <img src={images.mapimage} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
}