import React from 'react'
import images from '../../assets/images'
import Carousel from 'react-bootstrap/Carousel'
import {Link} from 'react-router-dom'

export const BannerSliderComponent: React.FC = () => {
    return (
        <div id="home" className="at-bannersiderarea">
            <Carousel className="at-homeslider">
                <Carousel.Item>
                    <img
                        src={images.sliderimg}
                        alt=""
                    />
                    <Carousel.Caption className="at-slidercontent">
                        <div className="at-container">
                            <div className="at-slidercontent-box">
                                <span className="at-slidertitle">Plan for Tomorrow</span>
                                <h1>DO YOU HAVE <span>YOUR CARD?</span></h1>
                                <Link to="/onboarding" className="at-btn at-primarybtn"><i className="icon-file"></i> CLICK HERE TO GET STARTED</Link>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src={images.sliderimg1}
                        alt=""
                    />
                    <Carousel.Caption className="at-slidercontent">
                        <div className="at-container">
                            <div className="at-slidercontent-box">
                                <span className="at-slidertitle">Plan for Tomorrow</span>
                                <h1>DO YOU HAVE <span>YOUR CARD?</span></h1>
                                <Link to="/onboarding" className="at-btn at-primarybtn"><i className="icon-file"></i> CLICK HERE TO GET STARTED</Link>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        src={images.sliderimg2}
                        alt=""
                    />
                    <Carousel.Caption className="at-slidercontent">
                        <div className="at-container">
                            <div className="at-slidercontent-box">
                                <span className="at-slidertitle">Plan for Tomorrow</span>
                                <h1>DO YOU HAVE <span>YOUR CARD?</span></h1>
                                <Link to="/onboarding" className="at-btn at-primarybtn"><i className="icon-file"></i> CLICK HERE TO GET STARTED</Link>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
