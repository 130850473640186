import React from 'react'
import {Layout} from '../components/layout'
import {BannerComponent} from '../components/shared'
import {Steps} from 'antd';
import {Application} from "../model/application";
import {CardDetailsComponent} from '../components/steps/card/card-details-component';
import {MemberDetails} from "../model/member-details";
import {MainMemberComponent} from "../components/steps/main-member/main-member-component";
import {IProps} from "../model/iprops";
import {DependentsComponent} from "../components/steps/dependents/dependents-component";
import {BeneficiariesComponent} from "../components/steps/beneficiaries/beneficiaries-component";
import {BenefitsComponent} from "../components/steps/benefits/benefits";
import {Quote} from "../model/quote";
import {CheckoutSummary} from "../components/steps/summary/checkout-summary";
import {CongratulationsComponent} from "../components/steps/congratulations/congratulations";
import axios from "axios";

interface PayAtHandback {
    hash: null | String
    status: null | String
    payatTranId: null | String
    payatReferenceNum: null | String
    digiapiResponseMsg: null | String
    digiapiPaymentMethod: null | String
    digiapiExternalRef: null | String
}

interface ComponentState {
    application: Application
    current: number
    processing: boolean
    postPayment: boolean
    payAtHandback?: PayAtHandback

}

export class OnboardingComponent extends React.Component<IProps, ComponentState> {
    steps: any[];
    postPayment: boolean;

    constructor(props: IProps) {
        super(props);
        let search = this.props?.location?.search
        let params = new URLSearchParams(search);
        let status = params.get("state");

        this.postPayment = status !== null;
        if (this.postPayment) {
            let payAtHandback = {
                hash: params.get("hash"),
                status: params.get("status"),
                payatTranId: params.get("payatTranId"),
                payatReferenceNum: params.get("payatReferenceNum"),
                digiapiResponseMsg: params.get("digiapiResponseMsg"),
                digiapiPaymentMethod: params.get("digiapiPaymentMethod"),
                digiapiExternalRef: params.get("digiapiExternalRef"),
            }

            this.state = {
                application: new Application(),
                current: 0,
                processing: false,
                postPayment: true,
                payAtHandback: payAtHandback
            };

            axios.post("https://api.nyaradzo.sahwi.net/policy/payment", payAtHandback).then((response) => {
                console.log(response.data)
                this.setState({
                    ...this.state,
                    current: 6,
                    application: response.data,
                    processing: false
                });
            }).catch(error => {
                console.log(error);
                this.setState({
                    ...this.state,
                    processing: false
                });
                //TODO: Handle
            });
        } else {
            this.state = {
                application: new Application(),
                current: 0,
                processing: false,
                postPayment: false,
            };
        }


        this.steps = [

            {
                title: 'Card',
                disabled: this.state.processing,
                content: <CardDetailsComponent application={this.state.application} current={this.state.current}
                    prev={() => this.prev()}
                    next={(application: Application, processed: boolean, accepted: boolean) => this.saveCard(application, processed, accepted)} />,
            },
            {
                title: 'Main Member',
                disabled: this.state.processing,
                content: <MainMemberComponent application={this.state.application} current={this.state.current}
                    prev={(member: MemberDetails) => this.saveMember(member, false)}
                    next={(member: MemberDetails) => this.saveMember(member, true)} />,
            },
            {
                title: 'Dependants',
                disabled: this.state.processing,
                content: <DependentsComponent application={this.state.application}
                    current={this.state.current}
                    prev={(dependants: MemberDetails[]) => this.saveDependants(dependants, false)}
                    next={(dependants: MemberDetails[]) => this.saveDependants(dependants, true)} />,
            },
            {
                title: 'Beneficiaries',
                disabled: this.state.processing,
                content: <BeneficiariesComponent application={this.state.application}
                    current={this.state.current}
                    prev={(beneficiaries: MemberDetails[]) => this.saveBeneficiaries(beneficiaries, false)}
                    next={(beneficiaries: MemberDetails[]) => this.saveBeneficiaries(beneficiaries, true)} />,
            },
            {
                title: 'Benefits',
                disabled: this.state.processing,
                content: <BenefitsComponent application={this.state.application}
                    current={this.state.current}
                    prev={(quote: Quote) => this.saveQuote(quote, false)}
                    next={(quote: Quote) => this.saveQuote(quote, true)} />,
            },
            {
                title: 'Checkout',
                disabled: this.state.processing,
                content: <CheckoutSummary application={this.state.application}
                    current={this.state.current}
                    prev={() => this.prev()}
                    next={() => this.checkOut()} />,
            },
            {
                title: 'Congratulations',
                disabled: this.state.processing,
                content: <CongratulationsComponent application={this.state.application}
                    current={this.state.current}
                    prev={() => this.prev()}
                    next={() => {
                    }} />,
            },
        ];


    }

    prev = () => {
        this.setState({
            ...this.state,
            current: this.state.current - 1
        });
    };

    next = () => {
        this.setState({
            ...this.state,
            current: this.state.current + 1
        });
    };

    saveCard = (details: Application, processed: boolean, accepted: boolean) => {
        let app = this.state.application
        Object.keys(details).forEach((key)=>{
            // @ts-ignore
            app[key] = details[key]
        })

        if (processed) {
            this.setState({
                ...this.state,
                application: app,
                current: 6
            });
        } else if (accepted){
            this.setState({
                ...this.state,
                application: app,
                current: 5
            });
        } else {
            this.setState({
                ...this.state,
                application: app
            });
            this.next();
        }
    };

    saveMember = (member: MemberDetails, proceed: boolean) => {
        let app = this.state.application
        app.member = member;

        this.setState({
            ...this.state,
            application: app
        });
        this.proceed(proceed)
    };

    saveDependants = (dependants: MemberDetails[], proceed: boolean) => {
        let app = this.state.application
        app.dependants = dependants;

        this.setState({
            ...this.state,
            application: app
        });
        this.proceed(proceed)
    };

    saveBeneficiaries = (beneficiaries: MemberDetails[], proceed: boolean) => {
        let app = this.state.application
        app.beneficiaries = beneficiaries;

        this.setState({
            ...this.state,
            application: app
        });
        this.proceed(proceed)
    };

    checkOut = () => {
        let app = this.state.application
        if (!app.locked) {
            app.locked = true
            this.setState({
                ...this.state,
                application: app
            });
        }

        this.proceed(true)
    };

    saveQuote = (quote: Quote, proceed: boolean) => {
        let app = this.state.application
        app.quote = quote;

        axios.put("https://api.nyaradzo.sahwi.net/policy", this.state.application)
            .then((response) => {
                this.proceed(proceed)
            }).catch(error => {
                console.log("Could not save quote")
            });
    };

    proceed = (proceed: boolean) => {
        if (proceed) {
            this.next();
        } else {
            this.prev();
        }
    }

    onChange = (current: number) => {
        this.setState({
            ...this.state,
            current: this.state.current
        });
    };

    render() {
        const { Step } = Steps;
        return (
            <Layout>
                <BannerComponent />
                <main className="at-main at-sectionspace">
                    {/* ************************************
                            Card details Pgae Start
                ************************************* */}
                    <div className="at-carddetails">
                        <div className="at-container">
                            <div className="at-sectionhead">
                                <span>Complete the Nyaradzo Onboarding Form</span>
                            </div>
                            <div className="at-content">
                                <Steps current={this.state.current} onChange={this.onChange}>
                                    {this.steps.map((item, index) => (
                                        <Step key={item.title}
                                            title={item.title}
                                            status={this.state.current < index ? 'wait' : "process"} />
                                    ))}
                                </Steps>
                                <div className="steps-content">{this.steps[this.state.current].content}</div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        );
    }
}
