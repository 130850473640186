import React from 'react'
import Table from 'react-bootstrap/Table'
import { Button, notification } from 'antd';
import { IProps } from "../../../model/iprops";
import axios from "axios";
import moment from "moment";

interface ComponentState {
    processing: boolean
}

export class CheckoutSummary extends React.Component<IProps, ComponentState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            processing: false
        };
    }


    checkout() {
        if (!this.props.application.locked) {
            let policyNumber = { policyNumber: this.props.application.card.policyNumber }

            this.setState({
                ...this.state,
                processing: true
            });

            axios.post("https://api.nyaradzo.sahwi.net/policy/update", policyNumber).then((response) => {
                this.props.next();
            }).catch(error => {
                this.setState({
                    ...this.state,
                    processing: false
                });
                notification.error({
                    message: 'System Error',
                    description: 'A System Error Occurred please try again later',
                });
            });
        }
    }


    render() {
        return (
            <div className="at-contentarea">
                <div className="at-sectionhead">
                    <h2>Summary details of elected <br /> benefits and beneficiaries</h2>
                    <div className="at-description">
                        <p>Please verify your policy details below for accuracy before activation.</p>
                    </div>
                </div>
                <div className="at-contentbox">
                    <div className="at-tablecard">
                        <div className="at-heading">
                            <h3>Main Member Details</h3>
                        </div>
                        <Table responsive borderless>
                            <tbody className="tight">
                                <tr>
                                    <td>Name</td>
                                    <td>{this.props.application.member.firstName} {this.props.application.member.lastName}</td>
                                </tr><tr>
                                    <td>Email</td>
                                    <td>{this.props.application.member.email}</td>
                                </tr><tr>
                                    <td>Mobile</td>
                                    <td>+{this.props.application.member.mobileNumber.code}{this.props.application.member.mobileNumber.phone?.substring(1)} </td>
                                </tr><tr>
                                    <td>DOB</td>
                                    <td>{this.props.application.member.dateOfBirth && moment(this.props.application.member.dateOfBirth).format('DD/MM/YYYY')}</td>
                                </tr><tr>
                                    <td>ID</td>
                                    <td>{this.props.application.member.idNumber}</td>
                                </tr><tr>
                                    <td>Passport</td>
                                    <td>{this.props.application.member.passportNumber}</td>
                                </tr><tr>
                                    <td>Nationality</td>
                                    <td>{this.props.application.member.nationality}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="at-tablecard">
                        <div className="at-heading">
                            <h3>Dependants</h3>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>DOB</th>
                                    <th>ID</th>
                                    <th>Passport</th>
                                    <th>Based In Zimbabwe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.application.dependants.map(function (dependant, index) {
                                    return <tr>
                                        <td>{dependant.firstName} {dependant.lastName}</td>
                                        <td>{dependant.email}</td>
                                        <td>+{dependant.mobileNumber.code}{dependant.mobileNumber.phone?.substring(1)}</td>
                                        <td>{dependant.dateOfBirth && moment( dependant.dateOfBirth).format('DD/MM/YYYY')}</td>
                                        <td>{dependant.idNumber}</td>
                                        <td>{dependant.passportNumber}</td>
                                        <td>
                                            {dependant.isDependentBasedInZimbabwe ? "Yes" : "No"}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="at-tablecard">
                        <div className="at-heading">
                            <h3>Beneficiary Members</h3>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>DOB</th>
                                    <th>ID</th>
                                    <th>Passport</th>
                                    <th>Based In Zimbabwe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.application.beneficiaries.map(function (beneficiary, index) {
                                    return <tr>
                                        <td>{beneficiary.firstName} {beneficiary.lastName}</td>
                                        <td>{beneficiary.email}</td>
                                        <td>+{beneficiary.mobileNumber.code}{beneficiary.mobileNumber.phone?.substring(1)}</td>
                                        <td>{beneficiary.dateOfBirth && moment( beneficiary.dateOfBirth).format('DD/MM/YYYY')}</td>
                                        <td>{beneficiary.idNumber}</td>
                                        <td>{beneficiary.passportNumber}</td>
                                        <td>
                                            {beneficiary.isDependentBasedInZimbabwe ? "Yes" : "No"}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                    <div className="at-tablecard">
                        <div className="at-heading">
                            <h3>Funeral Cover</h3>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Primary Plan</th>
                                    <th>Benefits</th>
                                    <th>Monthly Premium</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-capitalize">{this.props.application.quote?.product.planTypeName}</td>
                                    <td>
                                        {this.props.application.quote!.main.benefits.length > 0 &&
                                            <ul>
                                                {
                                                    this.props.application.quote?.main.benefits.map((benefit: any, index) => (
                                                        <li key={`main-benefit-${index}`}>{benefit.name}</li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                    </td>
                                    <td>{this.props.application.quote?.totalPremium}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    <div className="row pt-5">
                        <div className="col">
                            <Button type="primary"
                                size="large"
                                shape="round"
                                className="zright"
                                onClick={() => this.checkout()}
                                loading={this.state.processing}>
                                Activate
                            </Button>
                        </div>
                    </div>



                </div>
            </div>
        )
    }
}    
