import React, {createRef} from 'react'
import {Button, Dropdown, Menu, Modal, Tabs} from 'antd';
import {IProps} from "../../../model/iprops";
import {
    CheckCircleFilled,
    DownOutlined,
    ExclamationCircleFilled,
    SolutionOutlined,
    TeamOutlined
} from '@ant-design/icons';
import {DependentMemberComponent} from "./dependent-member-component";
import {getDependentRelations} from "../../../model/helpers";

interface ComponentState {
    activeKey: string,
    processing: boolean,
    panes: any[]

}

export class DependentsComponent extends React.Component<IProps, ComponentState> {
    newTabIndex: number;
    relations = getDependentRelations()

    constructor(props: IProps) {
        super(props);
        this.newTabIndex = 0;
        const panes = []
        let activeKey = ""
        for (const dependant of props.application.dependants) {
            const key = `key${this.newTabIndex++}`;
            const content = <DependentMemberComponent locked={this.props.application.locked}
                                                      member={dependant}
                                                      extendedRelationship={dependant.relationship === 'EXTENDED'}
                                                      ref={createRef()}></DependentMemberComponent>
            panes.push({title: dependant.relationship, key: key, content: content, valid: false, checked: false})
            activeKey = key;
        }

        this.state = {
            processing: false,
            activeKey: activeKey,
            panes: panes,
        };
    }

    componentDidMount() {
        this.checkDeps(false, false)
    }

    onChange = (activeKey: string) => {
        this.setState({
            ...this.state,
            activeKey: activeKey
        })
    }


    remove = (targetKey: string) => {
        let activeKey = this.state.activeKey
        let lastIndex = 0;
        this.state.panes.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });


        const panes = this.state.panes.filter(pane => pane.key !== targetKey);
        //If we are removing currently active tab
        if (activeKey === targetKey) {
            if (lastIndex > 0) {
                activeKey = panes[lastIndex].key
            } else if (panes.length > 0) {
                activeKey = panes[0].key;
            } else {
                activeKey = ""
            }
        }

        this.setState({
            ...this.state,
            panes: panes,
            activeKey: activeKey
        })
    }


    back = () => {
        this.checkDeps(false, true)
    }

    proceed = () => {
        this.checkDeps(true, false)
        this.setState({
            ...this.state,
            processing: true
        })
    }

    add = (relationship: string) => {
        let panes = this.state.panes;
        const key = `key${this.newTabIndex++}`;

        let member = {
            title: "",
            mobileNumber: {
                code: 27,
                phone: "",
                short: ""
            },
            mobileNumberVerified: false,
            dateOfBirth: null,
            email: "",
            firstName: "",
            idNumber: "",
            lastName: "",
            passportNumber: "",
            nationality: "",
            maritalStatus: "",
            relationship: relationship,
            gender: "",
            street: "",
            city: "",
            province: "",
            country: "",
            isDependentBasedInZimbabwe: false
        }

        const content = <DependentMemberComponent locked={this.props.application.locked}
                                                  member={member}
                                                  extendedRelationship={relationship === 'EXTENDED'}
                                                  ref={createRef()}></DependentMemberComponent>
        let pane = {title: relationship, key: key, content: content, valid: false, checked: false};
        panes.push(pane);

        this.setState({
            ...this.state,
            panes: panes,
            activeKey: key
        });

    }

    handleMenuClick = (e: any) => {
        this.add(this.relations[e.key].value)
    }

    checkDeps = async (proceed: boolean, back: boolean) => {
        const panes = this.state.panes
        if (panes.length === 0) {
            this.setState({
                ...this.state,
                processing: false
            });
            if (proceed) {
                this.props.next([])
            } else if (back) {
                this.props.prev([])
            }
        } else {
            let isValid = true;
            for (const pane of panes) {
                pane.checked = true
                pane.valid = true
                if (pane.content.ref !== null) {
                    const dependantComponent = pane.content.ref.current as DependentMemberComponent
                    if (dependantComponent !== null) {
                        pane.valid = await dependantComponent.isValid()
                    } else {
                        pane.valid = false
                    }
                }
                isValid = isValid && pane.valid
            }

            let dependants = []
            for (const pane of panes) {
                if (pane.content.ref !== null) {
                    const dependantComponent = pane.content.ref.current as DependentMemberComponent

                    if (dependantComponent !== null) {
                        dependants.push(await dependantComponent.getValues())
                    }
                }
            }

            this.setState({
                ...this.state,
                panes: panes,
                processing: false
            });


            if (proceed) {
                if (isValid) {
                    this.props.next(dependants)
                } else {
                    this.alert("Submission incomplete",
                        "You have errors highlighted in red in your submission. Kindly resolve them to proceed")
                }
            } else if (back) {
                this.props.prev(dependants)
            }
        }
    }

    alert = (title: string, description: string) => {
        Modal.error({
            title: title,
            content: (
                <div>You have errors in your submission. Check the form for errors, fix and resubmit.</div>
            ),
            onOk() {},
        });
    }

    onEdit = (targetKey: any, action: any) => {
        if (action === "remove") {
            this.remove(targetKey)
        }
    }

    render() {
        const menu = (
            <Menu onClick={this.handleMenuClick}>
                {this.relations.map(function (value, index) {
                    return <Menu.Item key={index}>
                        {value.label}
                    </Menu.Item>;
                })}
            </Menu>
        );

        let tabCloseable = true
        let operations = {}
        if (this.props.application.locked) {
            operations = {
                left: <Button type="primary" size="large">
                    Dependents
                </Button>
            };
            tabCloseable = false
        } else {
            operations = {
                left: <Dropdown overlay={menu}>
                    <Button type="primary" size="large">
                        Select Type of Dependent to Add <DownOutlined/>
                    </Button>
                </Dropdown>
            };
        }

        const {TabPane} = Tabs;

        return (
            <div className="at-contentarea">
                <div className="at-sectionhead">
                    <h2>Dependants</h2>
                    <div className="at-description">
                        <p>Please enter the details of the dependants.</p>
                    </div>
                </div>
                <div className="at-contentbox at-cardfromcontent">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <Tabs
                                onChange={this.onChange}
                                tabBarExtraContent={operations}
                                hideAdd
                                type="editable-card"
                                onEdit={this.onEdit}
                                activeKey={this.state.activeKey}
                            >
                                {this.state.panes.map(pane => (
                                    <TabPane tab=
                                                 {
                                                     <span>
                                                         {pane.checked && pane.valid ?
                                                             <CheckCircleFilled style={{color: '#52c41a'}}/> : ""}
                                                         {pane.checked && !pane.valid ?
                                                             <ExclamationCircleFilled style={{color: '#ff4d4d'}}/> : ""}
                                                         {pane.title}
                                             </span>
                                                 }
                                             key={pane.key}
                                             closable={tabCloseable}
                                             forceRender={true}>
                                        {pane.content}
                                    </TabPane>
                                ))}
                            </Tabs>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            <Button size="large"
                                    shape="round"
                                    icon={<SolutionOutlined />}
                                    onClick={() => this.back()}>
                                Previous
                            </Button>
                        </div>
                        <div className="col">
                            <Button type="primary"
                                    size="large"
                                    shape="round"
                                    className="zright"
                                    icon={<TeamOutlined />}
                                    onClick={() => this.proceed()}
                                    loading={this.state.processing}>
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
