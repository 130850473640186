import React from 'react'

export const BannerComponent: React.FC = () => {
    return (
        <div className="at-banner">
            <div className="at-container">
                <div className="at-bannercontent">
                <div className="at-bannercontent-box">
                    <span className="at-slidertitle">Plan for Tomorrow</span>
                    <h1>Collect Your <span>card here</span></h1>
                </div>
                </div>
            </div>
        </div>
    )
}