import React, {ChangeEvent} from 'react'
import {Checkbox, DatePicker, Form, FormInstance, Input, Radio, RadioChangeEvent} from 'antd';
import {MemberDetails} from "../../../model/member-details";
import moment from 'moment';
import {areaFilter, getGenderOptions} from "../../../model/helpers";
import CountryPhoneInput, {ConfigProvider} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

interface ComponentState {
    member: MemberDetails
    passportNumber: string
    idNumber: string
    processing: boolean
}

const genderOptions = getGenderOptions()

export interface DependentIProps {
    locked: boolean,
    member: MemberDetails
}

export class BeneficiaryComponent extends React.Component<DependentIProps, ComponentState> {
    constructor(props: DependentIProps) {
        super(props);
        let member = props.member;
        this.state = {
            member: member,
            passportNumber: member.passportNumber,
            idNumber: member.idNumber,
            processing: false
        };
    }

    formRef = React.createRef<FormInstance>();

    disabledDate = (current: any) => {
        // Can not select days before today and today
        return current > moment().endOf('day');
    }

    onGenderSelect = (e: RadioChangeEvent) => {
        var currentState = {...this.state}
        currentState.member.gender = e.target.value;
        this.setState(currentState)
    };

    onDOBChange = (date: any, dateString: string) => {

    };

    onPassportNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
                ...this.state,
                passportNumber: e.target.value
            }
        )
    };


    onIDNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
                ...this.state,
                idNumber: e.target.value
            }
        )
    };

    onFinish = (values: any) => {
        this.setState({
            member: {
                ...values,
            },
            processing: true
        });

        let member = this.state.member
        member.mobileNumberVerified = true
    }

    onFinishFailed = (errorInfo: any) => {};


    async isValid(): Promise<boolean> {
        let fi = this.formRef.current
        if (fi !== null) {
            return fi.validateFields().then(values => {
                return true
            }).catch(errorInfo => {
                return false
            });
        } else {
            return true
        }
    }

    getValues() {
        let fi = this.formRef.current
        if (fi !== null) {
            return fi.getFieldsValue(true)
        } else {
            return null
        }
    }


    render() {
        return (
            <ConfigProvider locale={en}
                            areaFilter={(area) => areaFilter(area)}>
            <Form
                name="basic"
                layout="vertical"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                autoComplete="off"
                ref={this.formRef}
            >
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.firstName}
                                rules={[{required: true, message: 'Please provide your first name!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.lastName}
                                rules={[{required: true, message: 'Please provide your last name!'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="Date of Birth"
                                name="dateOfBirth"
                                valuePropName={'date'}
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.dateOfBirth}
                                rules={[{required: true, message: 'Please provide your date of birth!'}]}
                            >
                                {this.state.member.dateOfBirth ?
                                    <DatePicker
                                        defaultValue={this.state.member.dateOfBirth}
                                        style={{width: '100%'}}
                                        onChange={this.onDOBChange}
                                        disabledDate={this.disabledDate}
                                        mode="date"
                                        format="DD MMMM YYYY"
                                        size="large"
                                        disabled={this.props.locked}/> :
                                    <DatePicker
                                        style={{width: '100%'}}
                                        onChange={this.onDOBChange}
                                        disabledDate={this.disabledDate}
                                        mode="date"
                                        format="DD MMMM YYYY"
                                        size="large"
                                        disabled={this.props.locked}/>
                                }
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="Gender"
                                name="gender"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.gender}
                                rules={[{required: true, message: 'Please select your gender!'}]}
                            >
                                <Radio.Group
                                    options={genderOptions}
                                    value={this.state.member.gender}
                                    onChange={this.onGenderSelect}
                                    optionType="button"
                                    buttonStyle="solid"
                                    size="large"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="form-group">
                            <Form.Item
                                label=""
                                name="isDependentBasedInZimbabwe"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.isDependentBasedInZimbabwe}
                                rules={[]}
                                valuePropName="checked"
                            >
                                <Checkbox>Is Dependent Based in Zimbabwe</Checkbox>
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="ID Number"
                                name="idNumber"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.idNumber}
                                rules={[{
                                    required: this.state.passportNumber.length === 0,
                                    message: 'Please provide your ID or passport number!'
                                }]}
                            >
                                <Input onChange={this.onIDNumberChange}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="Passport Number"
                                name="passportNumber"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.passportNumber}
                                rules={[{
                                    required: this.state.idNumber.length === 0,
                                    message: 'Please provide your passport or ID number!'
                                }]}
                            >
                                <Input onChange={this.onPassportNumberChange}/>
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="Mobile Number"
                                name="mobileNumber"
                                validateTrigger='onSubmit'
                                initialValue={{
                                    short: this.state.member.mobileNumber.short,
                                    code: this.state.member.mobileNumber.code,
                                    phone: this.state.member.mobileNumber.phone
                                }}
                                rules={[{required: true, message: 'Please provide a phone number!'}]}
                            >
                                <CountryPhoneInput
                                    disabled={this.props.locked}/>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                            <Form.Item
                                label="Email Address"
                                name="email"
                                validateTrigger='onSubmit'
                                initialValue={this.state.member.email}
                                rules={[{
                                    required: false,
                                    type: "email",
                                    message: "Please provide a valid email address!",
                                }]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                    </div>
                </div>

                <Form.Item
                    name="relationship"
                    initialValue={this.state.member.relationship}
                >
                    <Input type="hidden"/>
                </Form.Item>

                <Form.Item
                    name="title"
                    initialValue={this.state.member.title}
                >
                    <Input type="hidden"/>
                </Form.Item>

                <Form.Item
                    name="nationality"
                    initialValue={this.state.member.nationality}
                >
                    <Input type="hidden"/>
                </Form.Item>

                <Form.Item
                    name="country"
                    initialValue={this.state.member.country}
                >
                    <Input type="hidden"/>
                </Form.Item>

                <Form.Item
                    name="city"
                    initialValue={this.state.member.city}
                >
                    <Input type="hidden"/>
                </Form.Item>

                <Form.Item
                    name="province"
                    initialValue={this.state.member.province}
                >
                    <Input type="hidden"/>
                </Form.Item>

                <Form.Item
                    name="street"
                    initialValue={this.state.member.street}
                >
                    <Input type="hidden"/>
                </Form.Item>

            </Form>
            </ConfigProvider>

        )
    }
}
