import {Button, notification, Spin} from 'antd';
import React from "react";
import {IProps} from "../../../model/iprops";
import axios from "axios";
import {Quote} from "../../../model/quote";
import {FileTextOutlined, TeamOutlined} from "@ant-design/icons";

interface ComponentState {
    quote: Quote | null,
    benefits: Array<Benefit>,
    idValid: boolean,
    generating: boolean,
    processing: boolean,
    accomodationChecked: boolean,
    vehicleChecked: boolean,
    tentChecked: boolean,
    ticketChecked: boolean,
    nextCaption: string
}

interface Benefit {
    code: string,
    description: string
}

export class BenefitsComponent extends React.Component<IProps, ComponentState> {
    constructor(props: IProps) {
        super(props);
        let accomodationChecked = false,
            vehicleChecked = false,
            tentChecked = false,
            ticketChecked = false;
        let benefits: Benefit[] = []

        if (this.props.application.quote !== null) {
            this.props.application.quote.main?.benefits.forEach(benefit => {
                benefits.push({
                    code: benefit.shortName,
                    description: benefit.name
                })
                if (benefit.shortName === 'ACCOMODATION') {
                    accomodationChecked = true
                } else if (benefit.shortName === 'TENTS_CHAIRS_TOILETS') {
                    tentChecked = true
                } else if (benefit.shortName === 'MOTOR_VEHICLE') {
                    vehicleChecked = true
                } else if (benefit.shortName === 'TWO_ECONOMY_RETURN_AIR_TICKETS') {
                    ticketChecked = true
                }
            })
        }

        let nextCaption = "Accept"
        if (this.props.application.locked) {
            nextCaption = "Next"
        }

        this.state = {
            quote: this.props.application.quote,
            benefits: benefits,
            idValid: false,
            generating: false,
            processing: false,
            accomodationChecked: accomodationChecked,
            tentChecked: tentChecked,
            ticketChecked: ticketChecked,
            vehicleChecked: vehicleChecked,
            nextCaption: nextCaption
        };


        if (this.props.application.quote === null) {
            this.state = {
                ...this.state,
                generating: true
            }
            this.quote([]);
        }
    }

    invalidIdAlert = (message: String) => {
        notification.error({
            message: 'ID Verification Failed',
            description: message
        });
    };

    benefitsHandler = (benefit: Benefit) => {
        if (!this.props.application.locked) {
            const prevBenefits: Benefit[] = this.state.benefits;
            const findIndex = prevBenefits.findIndex(b => b.code === benefit.code);
            findIndex > -1 ? prevBenefits.splice(findIndex, 1) : prevBenefits.push(benefit);

            this.setState({
                ...this.state,
                benefits: prevBenefits
            });

            this.quote(prevBenefits);
        }
    }

    quote(benefits: Benefit[]) {
        if (!this.props.application.locked) {
            this.setState({
                ...this.state,
                generating: true
            });
            let tmpApp = {
                ...this.props.application,
                benefits: benefits
            }
            axios.post("https://api.nyaradzo.sahwi.net/quote", tmpApp).then((response) => {
                /* if (!response.data.isIdNumberValid) {
                     this.invalidIdAlert(response.data.idNumberDescription)
                 }*/
                this.setState({
                    ...this.state,
                    /*idValid: response.data.isIdNumberValid,*/
                    idValid: true,
                    generating: false,
                    quote: response.data
                })
            }).catch(error => {
                this.setState({
                    ...this.state,
                    generating: false
                });
                this.invalidIdAlert("An error occurred generating your quotation")
            });
        }
    }

    render() {
        return (
            <div className="at-contentarea">
                <div className="at-sectionhead">
                    <h2>Select benefits for your personalised plan</h2>
                    <div className="at-description">
                        <p>These benefits can be claimed for all the <br/> individuals covered by your policy</p>
                    </div>
                </div>
                <div className="at-contentbox">
                    <form className="at-themeform">

                        <div className="row">
                            <div className="col-8">

                                <div className="row">
                                    <div className="col">
                                        <div className="at-benefitholder">
                                            <label className="at-customcheckbox at-cardcheckbox">
                                                <input type="checkbox"
                                                       disabled={this.props.application.locked}
                                                       className="form-control"
                                                       onClick={() => this.benefitsHandler({
                                                           code: 'TWO_ECONOMY_RETURN_AIR_TICKETS',
                                                           description: '2 Return Air Tickets'
                                                       })}
                                                       name="airtickets"
                                                       defaultChecked={this.state.ticketChecked}/>
                                                <div className="at-benefitbox">
                                                    <div className="at-iconbox">
                                                        <i className="icon-tickets"></i>
                                                    </div>
                                                    <div className="at-content">
                                                        <h3>2 Return Air Tickets</h3>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="at-benefitholder">
                                            <label className="at-customcheckbox at-cardcheckbox">

                                                < input type="checkbox"
                                                        className="form-control"
                                                        disabled={this.props.application.locked}
                                                        onClick={() => this.benefitsHandler({
                                                            code: 'ACCOMODATION',
                                                            description: 'Accommodation'
                                                        })}
                                                        name="accomodation"
                                                        defaultChecked={this.state.accomodationChecked}
                                                />

                                                <div className="at-benefitbox">
                                                    <div className="at-iconbox">
                                                        <i className="icon-bed"></i>
                                                    </div>
                                                    <div className="at-content">
                                                        <h3>Accommodation</h3>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="at-benefitholder">
                                            <label className="at-customcheckbox at-cardcheckbox">
                                                <input type="checkbox"
                                                       className="form-control"
                                                       disabled={this.props.application.locked}
                                                       onClick={() => this.benefitsHandler({
                                                           code: 'MOTOR_VEHICLE',
                                                           description: 'Vehicle'
                                                       })}
                                                       name="vehicle"
                                                       defaultChecked={this.state.vehicleChecked}/>
                                                <div className="at-benefitbox">
                                                    <div className="at-iconbox">
                                                        <i className="icon-car"></i>
                                                    </div>
                                                    <div className="at-content">
                                                        <h3>Vehicle</h3>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <div className="row pt-5">
                                    <div className="col-4">
                                        <div className="at-benefitholder">
                                            <label className="at-customcheckbox at-cardcheckbox">
                                                <input type="checkbox"
                                                       disabled={this.props.application.locked}
                                                       className="form-control"
                                                       onClick={() => this.benefitsHandler({
                                                           code: 'TENTS_CHAIRS_TOILETS',
                                                           description: 'Tent'
                                                       })}
                                                       name="tents"
                                                       defaultChecked={this.state.tentChecked}/>
                                                <div className="at-benefitbox">
                                                    <div className="at-iconbox">
                                                        <i className="icon-car"></i>
                                                    </div>
                                                    <div className="at-content">
                                                        <h3>Tent</h3>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>

                                    </div>

                                    <div className="col-4">

                                    </div>

                                    <div className="col-4">

                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="at-planarea">
                                    <div className="at-plandetail">
                                        <div className="at-plandetailbox">
                                            <h3>Funeral Cover</h3>
                                            <ul className="at-plandetaillist">
                                                <li>
                                                    <span>Primary Cover</span><span
                                                    className="text-capitalize">{this.state.quote?.product?.productTypeName}</span>
                                                </li>
                                                <li>
                                                    <span>Primary Plan </span><span
                                                    className="text-capitalize">{this.state.quote?.product?.planTypeName}</span>
                                                </li>
                                                {this.state.benefits.length > 0 &&
                                                    <li><span>Optional Benefits</span>
                                                        <span>
                                                            <ul>
                                                                {
                                                                    this.state.benefits.map((benefit: Benefit, index) => (
                                                                        <li key={index}>{benefit?.description}</li>
                                                                    ))
                                                                }

                                                            </ul>
                                                        </span>
                                                    </li>
                                                }
                                            </ul>
                                            {this.state.generating &&

                                                <div className="at-footerbar">
                                                    <span>Generating Quote</span>&nbsp;

                                                    <Spin size="small"/>

                                                </div>
                                            }

                                            {!this.state.generating &&
                                                <div className="at-footerbar">
                                                    <span>Monthly Premium</span>
                                                    <strong>{this.state.quote?.totalPremium}</strong>
                                                </div>
                                            }

                                        </div>
                                        <div className="at-description">
                                            <p>By clicking next you accept the quoted amount.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row pt-5">
                            <div className="col">
                                <Button size="large"
                                        shape="round"
                                        icon={<TeamOutlined/>}
                                        disabled={this.state.generating}
                                        onClick={() => this.props.prev(this.state.quote)}>
                                    Previous
                                </Button>
                            </div>
                            <div className="col">
                                <Button type="primary"
                                        size="large"
                                        shape="round"
                                        className="zright"
                                        icon={<FileTextOutlined/>}
                                        disabled={this.state.generating || !this.state.idValid}
                                        onClick={() => this.props.next(this.state.quote)}
                                        loading={this.state.processing}>
                                    {this.state.nextCaption}
                                </Button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        )
    }
}
